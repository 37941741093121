import { IUserService } from './../../shared/userService';

import { ITranslationService } from './../i18n/translationService';

import { ITreeListScope } from './../treeListController/ITreeListScope';

import * as Constants from './../utils/constants';
import { IModalConfirmationWindowService } from './../utils/modalConfirmationWindowService';

import { IAuthenticationService } from './authenticationService';

export class LoginController {

    userName: string;
    password: string;

    private readonly dialogToken: string = "logon";
    
    static $inject = [
        "$scope",
        "$browser",
        "$location",
        "$timeout",
        "$window",
        "authenticationService",
        "modalConfirmationWindowService",
        "SSOLoginData",
        "translationService",
        "userService"
    ];

    constructor(
        public $scope: ITreeListScope,
        private $browser: any,
        private $location: ng.ILocationService,
        private $timeout: ng.ITimeoutService,
        private $window: ng.IWindowService,
        public authenticationService: IAuthenticationService,
        private modalConfirmationWindowService: IModalConfirmationWindowService,
        public SSOLoginData,
        private translationService: ITranslationService,
        public userService: IUserService
    ) {
        this.$window.onbeforeunload = (event) => {
            if (this.userService.isAuthenticated) {

               this.authenticationService.disconnect(); // disconnect websocket
                // this can not be used for Firefox and Chrome because onbeforeunload also triggers on page refresh (F5)
                // authenticationService.logoff();
            }
        };
    }

    $onInit() {
        if (this.userService.isAuthenticated) {
            this.authenticationService.loginIncorrect = false;
            this.$timeout(() => { this.userName = this.userService.getUserName(); }, 0);
            this.$timeout(() => { $("#pwInput").focus(); }, 0); // set focus on password field
        } else {
            this.$timeout(() => { $("#userInput").focus(); }, 0); // set focus on username field
        }
    }

    login(userName, password):void {
        this.authenticationService.loginBusy = true;
        // open modal window if login is slow
        this.modalConfirmationWindowService.showModalInfoDialog(this.$scope.textLabels.LOGGING_IN_TITLE,
            this.$scope.textLabels.LOGGING_IN_MESSAGE, "", null, Constants.modalWaitDelay, this.dialogToken);
        if (this.authenticationService.useSaml2) {
            // browse to the challenge endpoint to start Saml2 login
            this.$window.top.location.href = "https://api-omrp-mst.ortechealth.com"
                + "/api/Challenge";
            this.authenticationService.loginBusy = false;
            return;
        }
        this.authenticationService.login(userName, password)
            .then(
                (success) => {
                    this.authenticationService.loginBusy = false;
                    if (this.userService.isFullUser) {
                        this.translationService.switchToUserLanguage();
                        this.$location.path("/portal");
                    } else {
                        this.$location.path("/reports");
                    }
                    this.modalConfirmationWindowService.closeModalWindow(this.dialogToken);
                },
                (error) => {
                    if (error.status === 401) {
                        this.authenticationService.loginIncorrect = true;
                        this.authenticationService.loginFailed = false;
                        this.password = "";
                    } else {
                        this.authenticationService.loginIncorrect = false;
                        this.authenticationService.loginFailed = true;
                    }
                    this.authenticationService.loginBusy = false;
                    this.$timeout(() => { $("#pwInput").focus(); }, 0);
                    this.modalConfirmationWindowService.closeModalWindow(this.dialogToken);
                });
    };
}